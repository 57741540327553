@import '../base/typography';
@import '../base/queries';

.clients__title {
    line-height: 69px;
    font-size: 40px;
    letter-spacing: -0.03em;
    margin-top: 92px;
    margin-bottom: 42px;
    @include type('h2');
  }
  .client-list {
    padding: 0;
    margin: 0;
  }
  .client-list__item {
    display: inline-block;
    width: 25%;
    margin-bottom: 0;
  }
  
  /* autoprefixer grid: no-autoplace */
  @supports (grid-area: auto) {
    .client-list__item {
      display: inline-block;
      width: auto;
      margin-bottom: 0;
    }
    .client-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-column-gap: 0;
      grid-row-gap: 0;
      padding: 0;
      list-style: none;
      margin-top: 0;
      margin-bottom: 70px;
      @include mq($until: 580px) {
        grid-template-columns: 1fr 1fr;
      }
      @include mq($from: 580px, $until: 1135px) {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }
  .client-list__item {
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid var(--color-blue-dark);
      background-color: white;
      margin: 0 -1px -1px 0;
    }
    > div:before {
      content: '';
      width: 1px;
      margin-left: -1px;
      float: left;
      height: 0;
      padding-bottom: 100%;
    }
    > div:after {
      content: '';
      display: table;
      clear: both;
    }
    > div > * {
      flex-grow: 1;
      flex-basis: 100%;
      flex-shrink: 0;
      display: block;
    }
  }
  
  .client__logo {
    display: block;
    width: 100%;
    padding: 1px;
  }
  
  .client-list__show-more {
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
      display: flex;
      flex-direction: column;
      align-items: center;
      border: none;
      background: none;
      font-size: 22px;
      font-weight: bold;
      padding-bottom: 8px;
      &:hover {
        color: var(--color-red);
      }
      &:after {
        content: ' ';
        display: block;
        height: 36px;
        width: 16px;
        background: url(../images/down-arrow.svg) no-repeat bottom left;
      }
    }
  }