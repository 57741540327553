@import '../base/_widths';
@import '../base/_variables';
@import '../base/_typography';
@import '../base/_queries';

.blog-listing__title {
  grid-area: title;
  padding-top: 27px;
  margin-top: 27px;
  margin-bottom: 1.4em;
  border-top: 7px solid var(--color-primary);
}

.blog-listing__title  > span {
  display: block;
  max-width: $measure-wide - 30px;
  @include type('h1');
}

.side {
  grid-area: sidebar;
}

.blog-list {
  padding-left: 0;
  max-width: 840px;
}
.blog-list__item {
  list-style: none;
  padding-left: 0;
}
.blog-list__title {
  letter-spacing: -0.03em;
  @include type('h2');
  margin-top: 6px;
  margin-bottom: 0.5em;
  a,
  a:visited {
    text-decoration: none;
    color: var(--color-text);
    position: relative;
    display: inline-block;
    padding-right: 2px;
    &:hover {
      color: var(--color-primary);
      &:before {
        @include mq($from: 780px) {
          content: '';
          position: absolute;
          left: -50px;
          top: 10px;
          display: block;
          width: 44px;
          height: 30px;
          background: url('../images/blog-arrow-red.svg') no-repeat top left;
          color: var(--color-primary);
        }
      }
    }
    &:focus {
      outline: 2px solid;
    }
  }

}

@media (forced-colors: active) {
  .blog-list__title {
    a,
    a:visited {
      &:hover {
        color: black;
        &:before {
          background: url('../images/blog-arrow.svg') no-repeat top left;
          color: black;
        }
      }
    }
  }
}

.blog-list__excerpt {
  padding-bottom: 30px;
}

.pager {
  margin-top: 4rem;
  ol {
    list-style: none;
    padding: 0;
    list-style: none;
    display: flex;
    gap: 25px 0;
    flex-wrap: wrap;
  }
  a {
    border: 1px solid var(--color-primary);
    text-decoration: none;
    color: var(--color-primary);
    @include poly-fluid-sizing(
      'padding',
      (
        $breakpoint-tiny: (3px 6px),
        $breakpoint-small: (3px 8px),
        $breakpoint-medium: (4px 10px),
        $breakpoint-large: (5px 12px),
      )
    );

    @include poly-fluid-sizing(
      'margin-right',
      (
        $breakpoint-tiny: 4px,
        $breakpoint-small: 6px,
        $breakpoint-medium: 8px,
        $breakpoint-large: 10px,
      )
    );


    &:hover {
      text-decoration: underline;
    }

    &[aria-current="page"],
    &:active {
      color: black;
      border-color: black;
      text-decoration: none;
    }
  }
}