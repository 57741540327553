@import '../base/queries';

#cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    z-index: 2;
    transition: background-color 0.5s linear, transform 0.4s ease-out, opacity 0.5s ease-out;
}

  .cover {
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 10px;
    transition: margin 0.2s ease-in-out;
    &.menu-open {
      margin-top: 360px;
    }
  }
  
  .cover__collective {
    margin-left: 18%;
    padding-top: 23vh;
    margin-bottom: -6px;
    color: white;
    max-width: 616px;
    @include mq($until: 1024px) {
      width: 80vw;
      margin-left: 0;
    }
    @include mq($from: 321px, $until: 1024px, $and: '(orientation: portrait)') {
      padding-top: 30vw;
    }
  }
  
  .cover__collective {
      transition: opacity 2s ease-in;
      opacity: 0;
    }
  
.cover__strapline {
  transition: opacity 2s ease-in 2s;
  opacity: 0; 
}
  
  .cover__animation {
    line-height: 209px;
    font-size: 210px;
    letter-spacing: -0.07em;
    margin-left: 18%;
    font-weight: bold;
    min-height: 210px;
    color: var(--color-primary);
    @include mq($until: 1024px) {
      font-size: 15vw;
      line-height: 1.2;
      min-height: 200px;
    }
    @include mq($from: 1024px, $until: 1300px) {
      font-size: 15vw;
    }
    @include mq($until: 780px) {
      min-height: 150px;
    }
    @include mq($until: 321px, $and: '(orientation: portrait)') {
      min-height: 80px;
    }
    @include mq($from: 321px, $until: 376px, $and: '(orientation: portrait)') {
      min-height: 120px;
    }
  }
  
  .cover__strapline {
    margin-left: 33.3333333%;
    max-width: 720px;
    line-height: 47px;
    font-size: 42px;
    letter-spacing: -0.04em;
    color: white;
    @include mq($until: 1024px) {
      margin-left: 30px;
      font-size: 36px;
      line-height: 1.2;
      padding-right: 20px;
    }
    @include mq($until: 780px) {
      font-size: 30px;
    }
    @include mq($until: 321px, $and: '(orientation: portrait)') {
      font-size: 25px;
    }
  }
  
  @include mq($until: 900px, $and: '(orientation: landscape)') {
    .cover__collective {
      width: 70vw;
      margin-left: 30px;
    }
    .cover__animation {
      font-size: 100px;
      min-height: 120px;
    }
    .cover__strapline {
      font-size: 28px;
    }
  }