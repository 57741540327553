@import '../base/_queries';
@import '../base/_typography';

.standard-page .main>h1 {
  // max-width: 840px;
  line-height: 64px;
  font-size: 54px;
  letter-spacing: -0.03em;
  border-top: 4px solid #e26d31;
  padding-top: 27px;
  margin: 27px 0 26px 0;
}

.standard-page .content-section--text,
.standard-page .content-section--text-image {
  p+ul {
    margin-top: 1em;
  }

  ul+p {
    margin-top: 1em;
  }
}

.page-primary-image {
  margin: 0 calc(50% - 50vw);
  @include mq($from: $breakpoint-medium) {
    margin: 0;
  }
  margin-top: 2em;
  @include mq($from: $breakpoint-medium) {
    margin-top: 0;
  }
  img {
    width: 100%;
  }
}