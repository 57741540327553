/**
 * Global styles.
 */

@import '../base/_queries';
@import '../base/_variables';
@import '../base/_typography';
@import '../base/_fonts';

html,
body {
  height: 100%;
  hyphens: none;
}

html {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: apercu, Helvetica, Arial, sans-serif;
  background-color: var(--color-background);
  -webkit-font-smoothing: antialiased;
  padding: 0;
  margin: 0;
  color: var(--color-text);
  position: relative;
  @include type('body');
}

a,
a:visited {
  text-decoration: underline;
  color: var(--color-text);
  &:hover {
    color: var(--color-primary);
  }
}

p {
  margin-top: 0.78em;
  margin-bottom: 0;
  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    margin-bottom: 0.78em;
  }
}

// Aspect ratio handling.
// [style*='--aspect-ratio'] > :first-child {
//   width: 100%;
// }
// [style*='--aspect-ratio'] > img {
//   height: auto;
// }
// @supports (--custom: property) {
//   [style*='--aspect-ratio'] {
//     position: relative;
//   }
//   [style*='--aspect-ratio']::before {
//     content: '';
//     display: block;
//     padding-bottom: calc(100% / (var(--aspect-ratio)));
//   }
//   [style*='--aspect-ratio'] > :first-child {
//     position: absolute;
//     top: 0;
//     left: 0;
//   }
// }

.skip-to-main-link {
  position: absolute;
  left: -9999px;
  z-index: 999;
  padding: 1em;
  background-color: var(--color-blue-dark);
  opacity: 0;
}
.skip-to-main-link:focus {
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
  color: #fff;
}

.cover-wrapper {
  display: none;
}
// Display it if js is enabled.
.js .cover-wrapper {
  display: block;
}

.cover-wrapper {
  &-enter-active,
  &-leave-active {
    transition: all 0.7s;
  }
  &-enter,
  &-leave-to {
    opacity: 0;
  }
  // &-enter {
  // }
  &-leave-to {
    transform: translateY(-1000px);
  }
}

pre,
code,
code[class*='language-'] {
  font-family: var(--font-console);
  font-size: calc(var(--font-size) * 0.85);
  overflow: scroll;
  margin-bottom: var(--vertical-rhythm-spacing) !important;
}

pre:focus,
code:focus {
  outline: 2px solid var(--color-focus);
}

sub,
sup {
  position: relative;
  vertical-align: baseline;
  line-height: 0;
}

sup {
  top: -0.5rem;
}

sub {
  bottom: -0.25rem;
}

table {
  width: 100%;
  text-align: left;
  border: var(--table-border);
  border-collapse: collapse;
}

thead th {
  padding: var(--table-padding);
  background-color: var(--table-bg-color);
}

tbody td {
  padding: var(--table-padding);
}

tbody tr {
  border-bottom: var(--table-border);
}

