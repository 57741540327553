@import '../base/variables';
@import '../base/widths';
@import '../base/typography';

.content-section--title {
  letter-spacing: -0.04em;
  margin-top: 95px;
  margin-bottom: 40px;
	.title {
	  max-width: $measure-content;
    @include type('h2');
    margin: 0 0 0 0;
	}
	.subtitle {
	  max-width: $measure-content;
		color: var(--color-primary);
    @include type('h2');
    margin: 0;
	}
}

.content-section--title.content-section--bordered {
  padding-bottom: 22px;
  margin-bottom: 20px;
  border-bottom: 7px solid var(--color-primary);
}
