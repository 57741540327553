@import '../base/_typography';
@import '../base/_widths';

.accordion {
  border-bottom: 3px solid var(--color-blue-dark);
  margin-top: 0;
}

.accordion dd {
  margin: 0;
}

.accordion__pane {
  overflow: hidden;
}

.accordion__pane.-ba-is-hidden {
  max-height: 0 !important;
}

.accordion__pane.-ba-is-active {
  max-height: fit-content !important;
}

.accordion__pane.-ba-is-hidden .js-badger-accordion-panel-inner {
  visibility: hidden;
}

.accordion--initialized .js-badger-accordion-panel {
  transition: max-height ease-in-out 0.2s;
}

.accordion--initialized .js-badger-accordion-panel-inner {
  transition: visibility ease-in-out 0.2s;
}

.accordion__header {
  width: 100%;
  -webkit-appearance: button;
  text-align: left;
  font-size: 100%;
  font-family: inherit;
  padding-right: 0;
  padding-left: 0;
  @include poly-fluid-sizing(
    'padding-top',
    (
      $breakpoint-tiny: 3px,
      $breakpoint-large: 5px,
    )
  );
  @include poly-fluid-sizing(
    'padding-bottom',
    (
      $breakpoint-tiny: 3px,
      $breakpoint-large: 5px,
    )
  );
  margin: 0;
  border: none;
  border-top: 3px solid var(--color-blue-dark);
  line-height: 1;
  color: var(--color-blue-dark);
  background-color: transparent;
  @include poly-fluid-sizing(
    'font-size',
    (
      $breakpoint-tiny: 24px,
      $breakpoint-medium: 30px,
      $breakpoint-large: 42px,
    )
  );
  font-weight: bold;
  outline: none;
  cursor: pointer;
  transition: filter 0.3s ease-in-out;
  &:hover {
    filter: brightness(1.1);
  }
  span {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: $letter-tight;
    &:after {
      text-align: center;
      width: 50px;
      font-size: 70px;
      @include poly-fluid-sizing(
        'font-size',
        (
          $breakpoint-tiny: 40px,
          $breakpoint-large: 70px,
        )
      );
      @include poly-fluid-sizing(
        'width',
        (
          $breakpoint-tiny: 40px,
          $breakpoint-large: 50px,
        )
      );
      @include poly-fluid-sizing(
        'padding-right',
        (
          $breakpoint-tiny: 0px,
          $breakpoint-large: 40px,
        )
      );
      line-height: 1;
      font-weight: normal;
      padding-bottom: 2px;
      padding-top: 2px;
      color: var(--color-red);
      content: ' ';
      float: right; // Needed for IE11.
    }
  }
  &[aria-expanded='false']:focus {
    background-color: #eee;
  }
}

@media (forced-colors: active) {
  .accordion__header {
    background-color: transparent;
    &[aria-expanded='false']:focus {
      background-color: transparent;
      outline: 3px solid white;
      border-top: none;
      margin-top: 3px;
      filter: none;
    }
    span {
      background: none;
    }
    &:hover {
      filter: none;
      background-color: transparent;
      outline: 3px solid white;
      border-top: none;
      margin-top: 3px;
    }
  }
}

.js {
  .accordion__header span:after {
    content: '+';
  }
  .accordion__header.-ba-is-active span {
    &:after {
      content: '-';
    }
  }
}

.accordion-content {
  @include type('body');
}

.accordion-content {
  padding-top: 0.75em;
  padding-right: 23px;
  padding-bottom: 1em;
  padding-left: 23px;
  > * {
    max-width: $measure-content;
  }
}
