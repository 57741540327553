@import '../base/_typography';

.who-we-are,
.page-who-we-are {
  > .content-section--text:nth-child(2) {
    max-width: 100%;
    & > p,
    h2 {
      max-width: 912px;
    }
  }

  > .content-section:nth-child(2) {
    border-top: 7px solid var(--color-red);
    border-bottom: 7px solid var(--color-red);
    padding-top: 27px;
    padding-bottom: 34px;
    margin-top: 27px;
    margin-bottom: 36px;
  }

  > .content-section:nth-child(3) {
    font-weight: bold;
    font-size: 27px;
    line-height: 1.3;
    max-width: 912px;
    p {
      margin-top: 0.6em;
      margin-bottom: 0;
    }
  }

  > .content-section:nth-child(8) {
    padding-bottom: 22px;
    margin-bottom: 20px;
    border-bottom: 7px solid #f35c49;
  }

  > .content-section:nth-child(9) {
    font-weight: bold;
    font-size: 27px;
  }

  h1 {
    display: none;
    padding-bottom: 22px;
  }

  .content-section--text {
    h1,
    h2,
    h3 {
      line-height: 1.05;
      @include poly-fluid-sizing(
        'font-size',
        (
          $breakpoint-tiny: 30px,
          $breakpoint-large: 55px,
        )
      );
      letter-spacing: -0.05em;
      margin: 0;
    }
    h2 {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .content-section--accordion {
    max-width: none;
    .accordion-content {
      max-width: 912px;
      padding-left: 0;
    }
  }
}

// Page specific overrides.
.main.who-we-are,
.main.page-who-we-are {
  .content-section--text:nth-child(3) {
    letter-spacing: -0.035em;
    @include poly-fluid-sizing(
      'font-size',
      (
        $breakpoint-tiny: 22px,
        $breakpoint-large: 27px,
      )
    );
  }
  > .content-section--accordion {
    max-width: none;
    .accordion-content {
      max-width: 912px;
      padding-left: 0;
    }
  }
  > .content-section--custom-component {
    max-width: none;
  }
}
