@import '../base/_variables';
@import '../base/_queries';

.content-section--testimonial {
  padding-left: 2rem;
  position: relative;
  border-left: 5px solid var(--color-red);
  margin-left: 1rem;

  &:before {
    content: '\201C';
    font-family: Apercu;
    color: var(--color-red);
    font-style: normal;
    font-weight: 900;
    line-height: 57px;
    font-size: 54px;
    letter-spacing: -0.03em;
    position: absolute;
    top: -8px;
    left: -1rem;
    background-color: var(--color-background);
  }

  .testimonial__body {
    max-width: 760px;
    font-weight: normal;
  }
  blockquote {
    color: var(--color-blue-dark);
    font-weight: bold;
    margin: 0 0 30px 0;
    position: relative;
    @include mq($until: 930px) {
      &:before {
        top: -12px;
        left: -1rem;
      }
    }
  }

  .testimonial__body {
    margin-bottom: 22px;

    &:after {
      content: '\201D';
    }
  }

  .testimonial__person {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .testimonial__person-img {
    width: 3.5rem;
    height: 3.5rem;
    img {
      border-radius: 100%;
    }
  }

  .testimonial__person-name {
    font-weight: bold;
  }

  .testimonial__person-org {
    a,
    a:visited {
      color: var(--color-text);

      &:hover {
        color: var(--color-primary);
      }
    }
  }
}
