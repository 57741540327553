@import '../base/_queries';
@import '../base/_typography';

$c-grey-light: #7A858C;

/* autoprefixer grid: no-autoplace */
.content .our-work {
  @include mq($from: 780px) {
    display: grid;
    grid-template-areas:
      'projects projects'
      '. column';
    grid-template-columns: 1fr 2fr;
  }
}

.our-work > * {
  grid-area: column;
}

.our-work .projects {
  grid-area: projects;
}

.our-work .content-section {
  padding-left: 15px;
}

.page-our-work footer {
  @include mq($from: 780px) {
    margin-left: calc(33.3333333% + 15px);
  }
}

.page-our-work .call-to-action--contact {
  padding-left: 15px;
}

.projects-list {
    padding: 0;
    list-style: none;
    margin-bottom: 70px;
    @include mq($from: 430px) {
      margin-left: -25px;
    }
    backface-visibility: hidden;
    @include mq($from: 780px) {
      margin-left: -45px;
    }
  }
  .projects-filter__title {
    letter-spacing: -0.03em;
    padding-bottom: 16px;
    margin-bottom: 14px;
    border-bottom: 4px solid var(--color-text);
    // Hide the first word if there is no javascript.
    span.no-js-hidden {
      display: none;
    }
  }
  // If JavaScript is enabled display the full title.
  .js .projects-filter__title {
    span.no-js-hidden {
      display: inline;
    }
    span.no-js-shown {
      text-transform: lowercase;
    }
  }
  
  .projects-filter__reset {
    padding-top: 10px;
    text-decoration: none;
    color: #888;
  }
  
  /* autoprefixer grid: autoplace */
  .projects-filter__list {
    list-style: none;
    margin: 0;
    padding-left: 0;
    display: none;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto;
    @include mq($from: 780px) {
      width: 66%;
    }
  }
  
  // If JavaScript is enabled show the filter list.
  .js .projects-filter__list {
    display: grid;
  }
  
  .projects-filter__item {
    padding-left: 0;
  }
  .projects-filter__link,
  .projects-filter__link:visited {
    @include poly-fluid-sizing(
      'font-size',
      (
        $breakpoint-tiny: 17px,
        $breakpoint-large: 22px,
      )
    );
    letter-spacing: $letter-tight;
    line-height: 1.29;
    color: var(--color-text);
    text-decoration: none;
  }
  
  .projects-filter__link--active {
    font-weight: bold;
  }
  
  // The grid of items.
  .projects-list__item {
    a {
      text-decoration: none;
      width: 100%;
      img {
        transition: all 0.3s ease-in-out;
      }
      &:hover,
      &:focus {
        img {
          filter: brightness(1.4);
        }
        .project__title {
          color: black;
        }
        .project__subtitle {
          color: darken($c-grey-light, 10%);
        }
      }
    }
    position: relative;
    @include mq($from: 430px) {
      width: calc(100% / 2 - 28px);
      margin-left: 25px;
      display: inline-flex;
    }
    @include mq($from: 780px) {
      width: calc(100% / 3 - 50px);
      margin-left: 45px;
    }
    > div {
      display: flex;
      justify-content: flex-start;
      align-items: left;
    }
    > div:before {
      content: '';
      width: 1px;
      margin-left: -1px;
      float: left;
      height: 0;
      padding-bottom: 100%;
    }
    > div:after {
      content: '';
      display: table;
      clear: both;
    }
    img {
      display: block;
    }
    .projects-list__image {
      min-height: 1px; // IE 11 fix.
    }
    .projects-list__meta {
      margin: 0;
    }
    .project__title {
      margin-top: 0.5em;
      margin-bottom: 2px;
      line-height: 1.2;
      color: var(--color-text);
    }
    .project__subtitle {
      margin-top: 0;
      color: var(--color-grey-light);
      margin-bottom: 1em;
      @include mq($from: 430px) {
        margin-bottom: 2.5em;
      }
    }
    .project__title,
    .project__subtitle {
      transition: all 0.3s ease-in-out;
      line-height: 1.1;
      @include poly-fluid-sizing(
        'font-size',
        (
          $breakpoint-tiny: 21px,
          429px: 21px,
          430px: 19px,
          $breakpoint-medium: 20px,
          $breakpoint-large: 22px,
        )
      );
      font-weight: 700;
    }
  
    // Transition classes.
    &-move {
      transition: all 600ms ease-in-out 50ms;
    }
    &-enter-active {
      transition: all 300ms ease-out;
    }
  
    &-leave-active {
      transition: all 200ms ease-in;
      position: absolute;
      z-index: 0;
    }
  
    &-enter,
    &-leave-to {
      opacity: 0;
    }
    &-enter {
      transform: scale(0.9);
    }
  }
  
  .projects-list__empty {
    margin-left: calc(33% + 20px);
    margin-bottom: 80px;
  }
  
  .project__image {
    display: block;
  }