/**
 * Main content styling.
 */
 @import '../base/_queries';
 @import '../base/_typography';

 .content {
  display: grid;
  grid-template-areas:
      "title" 
      "sidebar"
      "main ";
      
  @include mq($from: $breakpoint-medium) {
    display: grid;
    grid-template-areas: 
      'sidebar title'
      'sidebar main';
    grid-template-columns: minmax(0%, 1fr) minmax(0%, 2fr);
    grid-template-rows: 1fr;
    .main {
      grid-area: main;
      display: block; // For IE
    }
    .sidebar {
      grid-area: sidebar;
      display: block; // For IE
    }
    .main--full-width {
      grid-column-start: sidebar;
      grid-column-end: span 2;
    }
  }
}

.layout {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0;
  @include mq($until: 600px) {
    padding: 0 10px;
  }
  @include mq($from: 600px, $until: 1640px) {
    padding: 0 20px;
  }
  @include mq($from: $breakpoint-medium) {
  }
}

.page-container {
  position: relative;
}

.with-cover {
  .page-container {
    > .content,
    > aside,
    > .footer {
      visibility: hidden;
    }
  }
}

.main {
  scroll-margin-top: 3.4em;
}