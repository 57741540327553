/* autoprefixer grid: off */
@import '../base/_queries';
@import '../base/_typography';
@import '../base/_widths';

.page-project .content .main {
  // IE 11 fix
  -ms-grid-column: 1;
}

.page-project {
  .main,
  .content-section {
    @include mq($from: $breakpoint-medium) {
      @supports (display: grid) {
        display: grid;
        grid-template-areas: 'sidebar main';
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 1fr;
      }
    }
  }

  .primary-image {
    margin: 0 calc(50% - 50vw);
    @include mq($from: $breakpoint-medium) {
      margin: 0;
    }
    margin-top: 2em;
    @include mq($from: $breakpoint-medium) {
      margin-top: 0;
    }
    img {
      width: 100%;
    }
  }

  .project__title {
    @include poly-fluid-sizing(
      'font-size',
      (
        $breakpoint-tiny: 32px,
        $breakpoint-large: 42px,
      )
    );
    letter-spacing: -0.03em;
    line-height: 1.15;
    @include mq($from: $breakpoint-medium) {
      margin-left: 33.33%;
      @supports (display: grid) {
        margin-left: 0;
        grid-area: main;
      }
    }
  }

  .content-section {
    grid-column-start: sidebar;
    grid-column-end: span 2;
    max-width: none;
  }

  .content-section--text {
    @include mq($from: $breakpoint-medium) {
      margin-left: 33.33%;
      @supports (display: grid) {
        margin-left: 0;
      }
      h2 {
        font-size: 30px;
        margin-top: 0;
        padding-right: 2em;
        grid-area: sidebar;
      }
      h2 + h3 {
        margin-top: 0;
      }
      h3 {
        grid-column-start: main;
      }
      h3 + p {
        margin-top: 0;
      }
      p {
        grid-column-start: main;
        max-width: $measure-content;
        margin-top: 0.9em;
        margin-bottom: 0;
        &:first-of-type {
          margin-top: 0;
        }
        &:last-of-type {
          margin-bottom: 0.9em;
        }
      }
      ul,
      ol {
        grid-column-start: main;
        max-width: $measure-content;
      }
    }
  }

  .content-section--image + .content-section--text {
    margin-top: 30px;
  }

  .content-section--image {
    grid-template-columns: 1fr;
    grid-column-start: sidebar;
    grid-column-end: main;
    .caption {
      grid-column-start: sidebar;
      grid-column-end: span 2;
    }
  }

  .content-section--two-images {
    @include mq($from: $breakpoint-medium) {
      grid-column-start: sidebar;
      grid-column-end: span 2;
      grid-template-columns: 1fr 1fr;
      .caption {
        grid-column-start: sidebar;
        grid-column-end: span 2;
      }
    }
  }

  .content-section--three-images {
    @include mq($from: $breakpoint-medium) {
      grid-column-start: sidebar;
      grid-column-end: span 2;
      grid-template-columns: 1fr 1fr;
    }
  }

  .content-section--testimonial {
    grid-column-start: main;
    display: flex;
    flex-direction: column;
  }

  .back-link {
    grid-column-start: main;
    font-weight: bold;
    @include mq($from: $breakpoint-medium) {
      margin-left: 33.33%;
      @supports (display: grid) {
        margin-left: 0;
      }
    }
  }

  .call-to-action--contact {
    grid-column-start: main;
    @include mq($from: $breakpoint-medium) {
      margin-left: 33.33%;
      @supports (display: grid) {
        margin-left: 0;
      }
    }
  }

  .project__introduction {
    grid-column-start: main;
    max-width: 720px;
    @include mq($from: $breakpoint-medium) {
      margin-left: 33.33%;
      @supports (display: grid) {
        margin-left: 0;
      }
    }
  }

  .tag-list-wrapper {
    grid-column-start: sidebar;
    grid-row-start: 2;
    grid-row-end: 4;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 3em;
    @include mq($from: $breakpoint-medium) {
      margin-left: 33.33%;
      @supports (display: grid) {
        margin-left: 0;
      }
    }
  }

  .tag-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    color: var(--color-grey-text);
  }

  .project__link {
    grid-column-start: main;
    margin-bottom: 3em;
    color: var(--color-grey);
    @include mq($from: $breakpoint-medium) {
      margin-left: 33.33%;
      @supports (display: grid) {
        margin-left: 0;
      }
    }
  }

  .content-section {
    margin-bottom: 2em;
  }
}
