/* Paragraph styling */
@import '../base/typography';

.content-section--text {
  max-width: 760px;
  h2 {
    @include type('h2');
  }
  p + h2 {
    margin-top: 1em;
  }
  p + ul,
  p + ol {
    margin-top: 1em;
  }
  ul {
    list-style: none;
    padding-left: 0;
    margin-top: 2px;
    margin-bottom: 2px;
    li {
      padding-left: 34px;
      background-image: url('../../_assets/images/bullet-arrow.svg');
      background-position: 0 10px;
      background-repeat: no-repeat;
      margin-bottom: 10px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
