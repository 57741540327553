@import '../base/_queries';
@import '../base/_variables';
@import '../base/_typography';


.blog-post .main article > h1 {
  max-width: 900px;
  margin-top: 8px;
  @include poly-fluid-sizing(
    'font-size',
    (
      $breakpoint-tiny: 42px,
      $breakpoint-large: 50px,
    )
  );
  line-height: 1.06;
}

.blog-post code {
  background-color: #d2d7e0;
  font-size: 19px;
  padding-left: 0.25em;
  padding-right: 0.25em;
  font-family: Consolas, 'Andale Mono WT', 'Andale Mono', 'Lucida Console',
    'Lucida Sans Typewriter', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono',
    'Liberation Mono', 'Nimbus Mono L', Monaco, 'Courier New', Courier,
    monospace;
}

.blog-post pre {
  padding: 1em;
  max-width: 760px;
  overflow-x: scroll;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  font-size: 17px;
  code {
    font-size: 17px;
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
  }
}

.blog-post div.code-toolbar {
  font-size: 0.6em;
  .toolbar .toolbar-item button {
    box-shadow: none;
    background-color: lighten(#f0d8d6, 10%);
    color: #1f1f1f;
    font-size: 1em;
    border-radius: 0;
    margin-top: 0.25em;
    margin-right: 0.25em;
    padding: 0.3em 0.5em;
    text-transform: uppercase;
    font-family: Consolas, 'Andale Mono WT', 'Andale Mono', 'Lucida Console',
      'Lucida Sans Typewriter', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono',
      'Liberation Mono', 'Nimbus Mono L', Monaco, 'Courier New', Courier,
      monospace;
  }
}

.blog-post {
  .content-section--image + .content-section--text,
  .content-section--image + .content-section--image,
  .content-section--text + .content-section--image {
    margin-top: 2em;
  }
  .content-section--image {
    max-width: 840px;
  }
  .content-section--text,
  .content-section--text-image {
    h3 {
      margin: 1.2em 0 1em 0;
    }
    p + ul {
      margin-top: 1em;
    }
  }
  .authors-section {
    max-width: 760px;
  }
}

/*Blog post meta*/

.blog-post__meta {
  line-height: 30px;
  font-size: 22px;
  letter-spacing: -0.03em;
  border-top: 7px solid var(--color-primary);
  padding-top: 38px;
  margin-top: 27px;
  display: flex;
  flex-wrap: wrap;
  font-weight: bold;
}

.blog-list__meta {
  line-height: 30px;
  font-size: 22px;
  letter-spacing: -0.03em;
  display: flex;
  font-weight: bold;
}

.blog-authors {
  padding-right: 0.5em;
  display: flex;
  flex: 1;

  a {
    text-decoration: none;
    color: var(--color-primary);
    margin-right: -4px ;
  }

  a:hover {
    color: var(--color-secondary);
  }
}

.blog-authors__slash {
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

.blog-authors__comma {
  margin-right: 0.2rem;
  color: var(--color-primary);
}

.blog-guest-authors__comma {
  margin-right: 0.2rem;
  color: var(--color-secondary);
}

/* Blog authors */

.authors__list {
  display: flex;
  gap: 50px;
  padding-left: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 576px) {
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: start;
  }
}

.authors__profile {
  list-style: none;
}

.authors__image {
  border-radius: 50%;
  width: 182.77px;
  height: 182.77px;
  @media (max-width: 576px) {
    width: 279px;
    height: 279px;
  }
}

.authors__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.authors__name {
  text-decoration: none;
  font-weight: 700;
}

.authors__heading {
  padding-bottom: 15px;
  padding-top: 38px;
  font-weight: 700;
  line-height: 38.28px;
  font-size: 1.75rem;
}

.authors__underline {
  border-top: 7px solid var(--color-primary);
  width: 369px; 
  @media (max-width: 576px) {
    width: 300px;
  }
}

/* Related blog posts */

.related-posts__heading {
  font-size: 1.75rem;
  font-weight: 700;
  padding-top: 2rem;
  border-top: 3px solid var(--color-red);
}

.related-posts__posts {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
  font-weight: 700;
  padding-left: 2.5rem;
}

.related-posts__link {
  text-decoration: none;
  &:before {
    content: '';
    position: absolute;
    left: -40px;
    top: 7px;
    display: block;
    width: 44px;
    height: 30px;
    background: url('../images/blog-arrow-red.svg') no-repeat top left;
    background-size: 30px;    
  }
}

.related-posts__title  {
  letter-spacing: -0.03em;
  margin-top: 6px;
  margin-bottom: 0.5em;
  font-size: 1.5rem;
  a,
  a:visited {
    text-decoration: none;
    color: var(--color-primary);
    position: relative;
    display: inline-block;
    padding-right: 2px;
  }
  a:hover {
    color: var(--color-blue-dark);
  }

}

.blog-author__link {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-bottom: 2rem;
}


