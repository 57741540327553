.blog-filter__container {
  #category {
    font-weight: 700;
  }
  .tags {
    margin-top: 0.5rem;
    margin-bottom: 0.7rem;
  } 
  select {
    background-color: #ffffff;
    font-size: 1.4rem;
    min-height: 31px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border: 0 none;
    border-radius: 7px;
    padding: 0.6rem 1.25rem;
    font-family: inherit;
  }

  .back-button {
    font-family: inherit;
    font-size: 1.2rem;
    font-weight: 700;
    color: var(--color-blue-dark);
    background-color: transparent;
    border: 0 none;
    text-align: left;
    margin-top: 2rem;
    cursor: pointer;
    &:hover {
      color: var(--color-red);

      svg {
        fill: var(--color-red);
      }
    }

    svg {
      fill: var(--color-text);
      display: inline-block;
      margin-bottom: -2px;
      margin-right: 2px;
    }
  }
}
