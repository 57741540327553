/**
 * Utility classes.
 */

/* Hide element until it receives focus */
.visible-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.visible-hidden:focus {
  clip: auto;
  height: auto;
  overflow: auto;
  position: absolute;
  width: auto;
}

/* Only show when JavaScript is disabled */
html.js .no-js {
  display: none;
}

html.no-js .no-js {
  display: block;
}
