@import '../base/_widths';
@import '../base/_variables';
@import '../base/_typography';

.contact,
.page-contact {
  border-top: 7px solid var(--color-primary);
  padding-top: 27px;
  margin-top: 27px;

  h1 {
    position: absolute;
    height: 1px;
    width: 1px;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: polygon(0 0, 0 0, 0 0);
    -webkit-clip-path: polygon(0 0, 0 0, 0 0);
    overflow: hidden !important;
  }

  .content-section--text {
    max-width: 760px;
    line-height: 1.125;
    letter-spacing: $letter-tight;
    font-weight: bold;
    // The text on this page has different font size from usual.
    h2,
    p {
      @include poly-fluid-sizing(
        'font-size',
        (
          $breakpoint-tiny: 32px,
          $breakpoint-medium: 32px,
          $breakpoint-large: 40px,
        )
      );
    }
    h2 {
      color: var(--color-primary);
      margin: 0 0 16px 0;
    }
    p {
      margin-top: 0;
      margin-bottom: 1em;
    }
    a,
    a:visited {
      color: var(--color-text);
      &:hover {
        color: var(--color-primary);
      }
    }
  }
}
