/* autoprefixer grid: off */
@import '../base/typography';
@import '../base/queries';
@import '../base/widths';

.people-list {
    padding: 0;
    list-style: none;
    margin-bottom: 70px;
    // For IE make a three column grid.
    display: flex;
    flex-wrap: wrap;
    margin-left: -30px;
    @supports (display: grid) {
      // Reset IE rules and display a proper grid.
      display: grid;
      margin-left: 0;
      margin-bottom: 0;
      grid-template-columns: 1fr 1fr;
      @include mq($from: 510px) {
        grid-template-columns: 1fr 1fr 1fr;
      }
      @include poly-fluid-sizing(
        'grid-column-gap',
        (
          $breakpoint-small: 15px,
          $breakpoint-medium: 20px,
          $breakpoint-large: 30px,
          $breakpoint-xlarge: 80px,
        )
      );
      @include poly-fluid-sizing(
        'grid-row-gap',
        (
          $breakpoint-small: 20px,
          $breakpoint-large: 50px,
          $breakpoint-xlarge: 70px,
        )
      );
    }
  }
  
  .people-list__item {
    display: inline-block;
    width: calc(33.333% - 30px);
    margin-left: 30px;
    margin-bottom: 50px;
    @supports (display: grid) {
      // Reset IE rules.
      width: auto;
      display: block;
      margin-left: 0;
      margin-bottom: 0;
    }
  }
  
  .people-list__item img {
    max-width: 100%;
  }
  
  .person__link {
    text-decoration: none;
    cursor: pointer;
    display: block;
    position: relative;
  }
  
  .person__image {
    display: block;
  }
  
  .person__image--empty {
    background-color: #e8e8e8;
    width: 100%;
    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  }
  
  .person__name {
    @include poly-fluid-sizing(
      'font-size',
      (
        $breakpoint-small: 17px,
        779px: 20px,
        780px: 18px,
        $breakpoint-xlarge: 22px,
      )
    );
    @include poly-fluid-sizing(
      'margin-top',
      (
        $breakpoint-small: 8px,
        $breakpoint-large: 15px,
      )
    );
    margin-bottom: 0;
    color: var(--color-blue-dark);
    text-decoration: none;
  }
  
  .person__roles {
    margin: 0;
    color: var(--color-blue-dark);
    @include poly-fluid-sizing(
      'font-size',
      (
        $breakpoint-small: 17px,
        779px: 20px,
        780px: 18px,
        $breakpoint-xlarge: 22px,
      )
    );
    font-weight: normal;
    line-height: 1.13;
  }