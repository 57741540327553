@import '../base/queries';
@import '../base/typography';

.content-section--three-images {
  margin: 0 calc(50% - 50vw);
  @include mq($from: $breakpoint-medium) {
    margin: 0;
  }
  > div {
    margin-bottom: 2em;
  }
  @include mq($from: 780px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 1em;
    grid-column-gap: 1em;
    > div {
      margin-bottom: 0;
    }

    picture {
      display: flex;
    }
  }

  .content-section--three-images--image-first {
    grid-column-start: 1;
    grid-row-start: 1;
    grid-row-end: span 2;
    display: flex;
    flex-direction: column;
    div,
    picture {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
    img {
      display: block;
      flex-grow: 1;
      object-fit: cover;
    }
  }
  .content-section--three-images--image-second {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 1;
  }
  .content-section--three-images--image-third {
    grid-column-start: 2;
    grid-row-start: 2;
    grid-row-end: 2;
  }
  .caption {
    grid-column-start: 1;
    grid-column-end: span 2;
  }
}
