/**
 * CSS variables.
 */

body {
  /* Widths and Spacing */
  --width-mega: 90rem; /* 1440px */
  --width-extra-large: 73.75rem; /* 1180px */
  --width-large: 60rem;
  --width-medium: 48rem;
  --width-small: 30rem;
  --breakpoint-medium: 768px;
  --width-container: var(--width-medium);
  --width-restricted-width-section: var(--width-medium);
  --width-sidebar-medium: 24rem;
  --width-sidebar-small: 16rem;
  --flow-space: 1rem;
  --spacing-smallest: calc(var(--spacing) * 0.25);
  --spacing-smaller: calc(var(--spacing) * 0.5);
  --spacing-small: calc(var(--spacing) * 0.75);
  --spacing: 1rem;
  --spacing-large: calc(var(--spacing) * 1.5);
  --spacing-larger: calc(var(--spacing) * 2);
  --spacing-largest: calc(var(--spacing) * 2.5);
  --spacing-mega: calc(var(--spacing) * 3);
  --spacing-padding-horizontal: var(--spacing);
  --line-height: 1.5;
  --vertical-rhythm-spacing: calc(var(--line-height) * 1rem);

  /* Colours */
  --color-red: #f35c49;
  --color-blue-dark: #23334b;

  --color-grey: #233540;

  --color-grey-text: #657081;

  --color-grey-light: #7a858c;
  --color-grey-lighter: #cbcfce;
  --color-grey-lightest: #f5f5f6;

  --color-grey-steel: #d2d7e0;

  --color-background: #fbfaf9;

  --color-primary: var(--color-red);
  --color-secondary: var(--color-blue-dark);
  --color-text: var(--color-blue-dark);

  /* Grey Scale */
  --color-white: #ffffff;
  --color-grey-medium: #385e91;
  --color-grey-darkish: #31507a;
  --color-grey-dark: #294263;
  --color-black: #1a2437;

  /* Colours Applied */
  --color-link: var(--color-black);
  --color-focus: var(--color-red);
  --color-hover: var(--color-red);
  --color-border: var(--color-grey-medium);
  --color-header: var(--color-blue-dark);

  /* Fonts */
  --font-base: apercu, helvetica, arial, sans-serif;
  --font-console: roboto-mono, monospace;

  /*
    Font sizes based on vertical rhythm of:
    - Font size: 18px;
    - Scale factor: 1.25
    - Details: https://www.gridlover.net/try
  */
  --font-size: 1.25rem;
  --font-size-smallest: calc(var(--font-size) * 0.5);
  --font-size-smaller: calc(var(--font-size) * 0.75);
  --font-size-small: calc(var(--font-size) * 0.875);
  --font-size-medium: calc(var(--font-size) * 1);
  --font-size-large: calc(var(--font-size) * 1.2778);
  --font-size-larger: calc(var(--font-size) * 1.5555);
  --font-size-largest: calc(var(--font-size) * 1.9444);
  --font-size-h1: clamp(
    var(--font-size-larger),
    10vw,
    var(--font-size-largest)
  );
  --font-size-h2: clamp(var(--font-size-large), 8vw, var(--font-size-larger));
  --font-size-h3: clamp(var(--font-size-medium), 6vw, var(--font-size-large));
  --font-size-h4: var(--font-size-medium);
  --font-size-h5: var(--font-size-medium);
  --font-size-h6: var(--font-size-medium);

  /* Heading colours */
  --color-text: var(--color-blue-dark);
  --heading-1-color: var(--color-grey-dark);
  --heading-2-color: var(--color-grey-dark);
  --heading-3-color: var(--color-grey-dark);
  --heading-4-color: var(--color-text);
  --heading-5-color: var(--color-text);
  --heading-6-color: var(--color-text);

  /* Heading line-height */
  --heading-1-line-height: var(--line-height);
  --heading-2-line-height: var(--line-height);
  --heading-3-line-height: var(--line-height);
  --heading-4-line-height: var(--line-height);
  --heading-5-line-height: var(--line-height);
  --heading-6-line-height: var(--line-height);

  /* Heading font-weight */
  --heading-font-weight: bold;
  --heading-1-font-weight: var(--heading-font-weight);
  --heading-2-font-weight: var(--heading-font-weight);
  --heading-3-font-weight: var(--heading-font-weight);
  --heading-4-font-weight: var(--heading-font-weight);
  --heading-5-font-weight: var(--heading-font-weight);
  --heading-6-font-weight: var(--heading-font-weight);

  /* Borders */
  --border-width: 1px;
  --border-width-large: 5px;
  --border-color: var(--color-grey-light);
  --border-color-dark: var(--color-grey-dark);
  --border-color-alert: var(--color-black);
  --border-style: solid;
  --border-radius: var(--spacing-smallest);
  --border: var(--border-width) var(--border-style) var(--border-color);
  --border-large: var(--border-width-large) var(--border-style)
    var(--border-color);
  --border-dark: var(--border-width) var(--border-style)
    var(--border-color-dark);

  /* Animation */
  --transition-time: 0.3s;
  --sidebar-transition-time: 0.5s;
}
