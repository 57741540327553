@import '../base/_variables';
@import '../base/_queries';
@import '../base/_typography';

.caption {
  margin-top: 0.25em;
  margin-right: 10px;
  margin-left: 10px;
  color: var(--color-grey);
  @include poly-fluid-sizing('font-size', ($breakpoint-tiny: 16px, $breakpoint-large: 19px));
  @include mq($from: 600px) {
    margin-right: 20px;
    margin-left: 20px;
  }
  @include mq($from: $breakpoint-medium) {
    margin-right: 0;
    margin-left: 0;
  }
}
