@import '../base/queries';
@import '../base/typography';

.content-section--two-images {
  margin: 0 calc(50% - 50vw);
  @include mq($from: $breakpoint-medium) {
    margin: 0;
  }
  .image-first {
    margin-bottom: 2em;
  }
  @include mq($from: 780px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1em;
    margin-bottom: 2em;
    .image-first,
    .image-second {
      margin-bottom: 0;
    }
  }

  img {
    display: block;
    object-fit: cover;  }
  .image-first {
    grid-column-start: 1;
  }
  .image-second {
    grid-column-start: 2;
  }
  .caption {
    grid-column-start: 1;
    grid-column-end: span 2;
  }
}
