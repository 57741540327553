/**
 * Grid.
 */

.header {
  grid-area: header;
}

// .main {
//   display: grid;
//   grid-template-areas: 'sidebar main';
//   grid-template-columns: 1fr 2fr;
//   grid-template-rows: 1fr;
// }


.footer {
  grid-area: footer;
}


