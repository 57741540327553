.guest-authors__container {
  display: flex;
  gap: 50px;

  @media (max-width: 576px) {
    flex-direction: column;
    align-items: center;
  }
}

.guest-authors__introduction {
  display: flex;
}

.guest-authors__information {
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex: 1;
}

.guest-authors__list {
  display: flex;
  gap: 50px;
  padding-left: 0;
  flex-wrap: wrap;
  list-style: none;
  margin-top: 20px;
  margin-bottom: 20px;
}

.guest-authors__image {
  border-radius: 50%;
  width: 182.77px;
  height: 182.77px;

  @media (max-width: 576px) {
    width: 279px;
    height: 279px;
  }
}

.guest-authors__no-img--wrapper {
  width: 182.77px;
  height: 182.77px;

  @media (max-width: 576px) {
    width: 279px;
    height: 279px;
  }
}

.guest-authors__no-img--name {
  background: radial-gradient(ellipse at center,
    #23334B 0%,
    #23334B 70%,
    rgba(0, 128, 172, 0) 70.3%);
  height: 100%;
  width: 100%;
  color: white;
  position: relative;
  p {
    font-size: 1.4rem;
    text-align: center;
    font-weight: 700;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
  }

}

.guest-authors__name {
  font-weight: 700;
}

.guest-authors__organisation {
  font-weight: 700;
  color: var(--color-primary);
  a {
    color: var(--color-primary);
    text-decoration: none;
  }

  a:hover {
    color: var(--color-secondary);
  }
}

.guest-authors__heading {
  padding-bottom: 15px;
  padding-top: 38px;
  font-weight: 700;
  line-height: 38.28px;
  font-size: 1.75rem;
}

.guest-authors__underline {
  border-top: 7px solid var(--color-primary);
  width: 369px; 
  @media (max-width: 576px) {
    width: 300px;
  }
}
