@import '../base/_typography';

.content-section--text-image {
  max-width: 760px;
  h2 {
    @include type('h2');
  }
  h3 {
    @include type('h3');
  }
}
