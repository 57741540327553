@import '../base/_widths';
@import '../base/_typography';
@import '../base/queries';

$c-grey-steel: #d2d7e0;
$c-primary: #f35c49;

.main.our-services,
.main.page-our-services {
  > h1 {
    padding-top: 27px;
    margin-top: 27px;
    border-top: 7px solid var(--color-red);
  }
  > h1 span {
    display: block;
    max-width: $measure-wide - 30px;
    @include type('h1');
  }
  .content-section--accordion {
    max-width: none;
    border-bottom: none;
    margin: 43px calc(50% - 50vw) 0;
    @include mq($from: $breakpoint-medium) {
      margin: 43px 0 0 0;
    }
    dt .accordion__header {
      color: var(--color-grey);
      padding: 8px 20px 8px 20px;
      margin: 0;
      border-top: none;
      span:after {
        color: var(--color-blue-dark);
        margin-right: 20px;
        @include mq($until: 600px) {
          margin-right: 20px;
        }
      }
    }
    dt:nth-of-type(odd) {
      .accordion__header {
        background-color: var(--color-grey-steel);
        &[aria-expanded='false']:focus {
          background-color: darken($c-grey-steel, 12%);
        }
      }
    }
    dt:nth-of-type(even) {
      .accordion__header {
        background-color: rgba($c-primary, 0.9);
        &[aria-expanded='false']:focus {
          background-color: darken($c-primary, 10%);
        }
      }
    }
    dd .accordion-content {
      padding-top: 1.5em;
    }
    dd:nth-of-type(odd) {
      .accordion-content {
        background-color: #ffffff;
      }
    }
    dd:nth-of-type(even) {
      .accordion-content {
        background-color: #ffffff;
      }
    }
  }

  .content-section--testimonial {
    margin-top: 56px;
    margin-bottom: 72px;
    max-width: 760px;
  }

  .content-section--title {
    max-width: 760px;
  }

  .content-section--testimonial + .content-section--text {
    font-size: 40px;
    line-height: normal;
    font-weight: bold;
    letter-spacing: -0.04em;
    margin-top: 128px;
  }

  > .content-section--text {
    @include type('body-large');
  }
  > .content-section ~ .content-section {
    @include type('body');
  }
}
@media (forced-colors: active) {
  .our-services,
  .page-our-services {
    .content-section--accordion {
      dt:nth-of-type(odd),
      dt:nth-of-type(even) {
        .accordion__header {
          background-color: transparent;
          &[aria-expanded='false']:focus {
            background-color: transparent;
            outline: 3px solid black;
          }
        }
      }
    }
  }
}
