@import '../base/_widths';
@import '../base/_typography';

.main.page-404 > h1 {
    padding-top: 27px;
    margin-top: 27px;
    border-top: 7px solid var(--color-red);
  }
  .main.page-404 > h1 span {
    display: block;
    max-width: $measure-wide - 30px;
    @include type('h1');
  }