@import '../base/queries';
@import '../base/typography';

.page-person .main,
.page-person .content-section {
  @include mq($from: 780px) {
    display: grid;
    grid-template-areas: 'sidebar main';
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
  }
}
.page-person {
  .person-image {
    margin-top: 2em;
    margin-top: 2em;
    margin-left: -10px;
    margin-right: -10px;
    @include mq($from: 600px) {
      margin-left: 0;
      margin-right: 0;
    }
    @include mq($from: 780px) {
      margin-top: 0;
    }
    img {
      display: block;
      max-width: 100%;
    }
    padding-bottom: 50px;
  }

  .person__title {
    grid-column-start: main;
    @include poly-fluid-sizing(
      'font-size',
      (
        $breakpoint-tiny: 32px,
        $breakpoint-large: 42px,
      )
    );
    letter-spacing: -0.03em;
    line-height: 1.15;
    margin-bottom: 0;
  }

  .person__roles {
    grid-column-start: main;
    @include poly-fluid-sizing(
      'font-size',
      (
        $breakpoint-tiny: 32px,
        $breakpoint-large: 42px,
      )
    );
    letter-spacing: -0.03em;
    line-height: 1.15;
    margin: 0 0 30px 0;
    font-weight: bold;
    color: var(--color-primary);
  }

  .person__pronouns {
    grid-column-start: main;
  }

  .person__pronouns > span {
    grid-column-start: main;
    font-weight: 700;
  }

  .person__blogs {
    grid-column-start: main;
    display: flex;
    align-items: center;
    gap: 15px;
    padding-top: 3.3rem;
    padding-bottom: 3.3rem;
    img {
      width: 51px;
      border-radius: 50%;
    }
  }

  .content-section {
    grid-column-start: sidebar;
    grid-column-end: span 2;
    max-width: none;
  }

  // Make the first paragraph bold. This is done by making all content-section
  // paragraphs bold and then in the next selector reseting all but the first one.
  .main > .content-section p {
    font-weight: bold;
  }

  .main > .content-section ~ .content-section p {
    font-weight: normal;
  }

  .content-section--text {
    h2 {
      @include poly-fluid-sizing(
        'font-size',
        (
          $breakpoint-tiny: 22px,
          $breakpoint-medium: 23px,
          $breakpoint-large: 27px,
        )
      );
      padding-right: 1em;
    }
    @include mq($from: 780px) {
      h2 {
        margin-top: -2px;
        grid-area: sidebar;
      }
      p {
        grid-column-start: main;
        max-width: 720px;
      }
    }
  }

  .content-section--image {
    @include mq($from: 780px) {
      grid-column-start: main;
    }
  }

  .content-section--two-images {
    @include mq($from: 780px) {
      grid-column-start: sidebar;
      grid-column-end: span 2;
      grid-template-columns: 1fr 1fr;
    }
  }

  .content-section--three-images {
    @include mq($from: 780px) {
      grid-column-start: sidebar;
      grid-column-end: span 2;
      grid-template-columns: 1fr 1fr;
    }
    padding-bottom: 2em;
  }

  .person__links {
    grid-column-start: main;
    padding-left: 0;
    display: flex;
    list-style: none;
    color: var(--color-text);
    font-weight: bold;
  }

  .person__link {
    padding-right: 0.25em;
    &:after {
      content: '|';
      padding-left: 0.25em;
    }
    &.last {
      &:after {
        content: '';
      }
    }
    a {
      color: var(--color-text);
      text-decoration: underline;
    }
  }

  .back-link {
    grid-column-start: main;
    font-weight: bold;
  }

  .call-to-action--contact {
    grid-column-start: main;
  }
}
