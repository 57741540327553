/**
 * Print styling.
 */

@media print {
  .l-sidebar,
  .breadcrumb,
  .toc {
    display: none;
  }

  .l-body {
    margin-left: 0;
  }

  .main {
    padding: 0 !important;
  }
}
