@import '../base/_queries';
@import '../base/typography';

.footer {
  @include mq($from: $breakpoint-medium) {
    border-top: 7px solid var(--color-red);
    padding-top: 58px;
  }

  padding-bottom: 30px;
  margin-top: 60px;

  @include mq($from: $breakpoint-medium) {
    margin-left: 33.3333333%;
  }
}

/* autoprefixer grid: autoplace */
.footer-links {
  font-weight: bold;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;

  @include mq($from: $breakpoint-medium) {
    text-align: center;
    grid-template-columns: repeat(3, 1fr);
  }

  @include mq($from: $breakpoint-xlarge) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.footer-links__group {
  text-align: left;
  line-height: 29px;
  font-size: 21px;
  letter-spacing: -0.03em;
  padding-right: 1em;
  padding-bottom: 2em;
  min-width: 180px;

  a,
  a:visited {
    display: block;
  }
}

.footer-links__logo {
  padding-top: 8px;
  display: block;
  padding-bottom: 1em;

  @include mq($from: 550px) {
    display: inline-block;
    padding-right: 1em;
  }

  @include mq($from: 910px) {
    padding-right: 2em;
  }

  color: #1e2e37;
}

.footer-badges {
  display: flex;
  justify-content: left;
  width: 100%;
  padding-bottom: 1em;
  box-sizing: border-box;

  @include mq($from: 550px) {
    padding-right: 1em;
  }

  @include mq($from: 910px) {
    padding-right: 2em;
  }
}

.footer-badges__cyber {
  width: 100%;
  max-width: 100%;
  border: none;
}

.footer-links__logo_fill {
  svg {
    fill: var(--color-blue-dark);
  }

  &:hover svg {
    fill: #f35c49;
  }
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .footer-links__logo {
    color: white;
  }
}

@media (forced-colors: active) and (prefers-color-scheme: light) {
  .footer-links__logo {
    color: black;
  }
}

.footer__copyright {
  padding-top: 57px;
  line-height: 18px;
  font-size: 14px;
  letter-spacing: -0.02em;
}
