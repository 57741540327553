/**
 * Jump link navigation styling.
 */

 .a11y-nav {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
}

.a11y-nav ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.a11y-nav li {
  position: absolute;
  top: 10px;
  left: 10px;
  width: calc(100% - 10px);
}

.a11y-nav a {
  padding: 0.5rem;
  width: calc(100% - 20px) !important;
  text-align: center;
  font-weight: bold;
  background-color: var(--color-white);
  box-shadow: none;
  border: 5px solid var(--color-focus);
}
