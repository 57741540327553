@import '../base/typography';

.cta {
  width: fit-content;
  margin: 3rem 0;
  background-color: var(--color-blue-dark);
  &__content {
    max-width: 760px;
    padding: 1.5rem;
  }

  &__title {
    @include type('h2');
    margin: 0;
    margin-bottom: 1rem;
    color: var(--color-primary);
  }

  div.cta__body {
    color: var(--color-white);
    margin-bottom: 1rem;
  }

  &__button {
    display: inline-block;
    background-color: var(--color-white);
    color: var(--color-blue-dark);
    font-size: var(--font-size-medium);
    font-weight: bold;
    padding: 0.5rem 1rem;
    border: 2px solid transparent;
    transition: transform 0.1s ease, color 0.2s ease;

    &:active {
      transform: translateY(2px);
    }
  }

  &--light {
    background-color: var(--color-white);

    div.cta__body {
      color: var(--color-blue-dark);
    }

    .cta__button {
      background-color: var(--color-blue-dark);
      color: var(--color-white);

      &:hover {
        color: var(--color-primary);
      }
    }
  }
}
