// Variables related to typography and font sizes.
// Important! This should only have non-printing scss in it as it
// will be imported in every component.

// The following variables are presented as maps for use in the
// poly-fluid-sizing mixin. They are shown as a key/value pair,
// the key being the breakpoint and the value the size on the font.

$breakpoint-tiny: 320px;
$breakpoint-small: 375px;
$breakpoint-medium: 768px;
$breakpoint-large: 1112px;
$breakpoint-xlarge: 1440px;


// The usual page heading size.
$heading-page: ($breakpoint-tiny: 38px, $breakpoint-large: 55px);
// A common size for H2's.
$heading-large: ($breakpoint-tiny: 30px, $breakpoint-large: 42px);
// A common size for H3's.
$heading-medium: ($breakpoint-tiny: 26px, $breakpoint-large: 34px);
// The standard body font size.
$body-copy: ($breakpoint-tiny: 18px, $breakpoint-large: 23px);
// When a larger font size is used in body copy.
$body-large: ($breakpoint-tiny: 20px, $breakpoint-large: 27px);

$letter-tight: -0.03em;
$letter-tighter: -0.04em;

@import '../../../../node_modules/poly-fluid-sizing/poly-fluid-sizing';

// Set a default for the mixin.
@mixin type($style: 'body') {
  @if $style=='body' {
    @include poly-fluid-sizing('font-size', $body-copy);
    letter-spacing: $letter-tight;
    line-height: 1.29;
  }

  @if $style=='h1' {
    @include poly-fluid-sizing('font-size', $heading-page);
    letter-spacing: $letter-tight;
    line-height: 1.18;
  }

  @if $style=='h2' {
    @include poly-fluid-sizing('font-size', $heading-large);
    letter-spacing: $letter-tight;
    line-height: 1.119;
  }

  @if $style=='h3' {
    @include poly-fluid-sizing('font-size', $heading-medium);
    letter-spacing: $letter-tight;
    line-height: 1.119;
  }

  @if $style=='body-large' {
    @include poly-fluid-sizing('font-size', $body-large);
    letter-spacing: $letter-tight;
    line-height: 1.29;
  }
}

.screen-reader-only {
  position: absolute;
  height: 1px;
  width: 1px;
  clip: rect(1px 1px 1px 1px); // IE 6 and 7
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  overflow: hidden !important;
}
