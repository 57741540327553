@import '../base/_typography';

.call-to-action--contact {
    margin-top: 90px;
    h3 {
      margin: 0;
      @include type('h2');
      @include poly-fluid-sizing('font-size', ($breakpoint-tiny: 22px, $breakpoint-medium: 34px, $breakpoint-large: 37px));
      color: var(--color-red);
      max-width: 900px;
    }
    a, a:visited {
      text-decoration: none;
      &:hover h3 {
        color: var(--color-blue-dark);
      }
    }
  }