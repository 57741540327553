.back-link,
.back-link:visited {
  color: var(--color-blue-dark);
  text-decoration: none;
  display: block;
  margin-top: 4em;
  margin-bottom: 2.5em;
  font-weight: bold;
  svg {
    fill: var(--color-blue-dark);
    display: inline-block;
    margin-bottom: -2px;
  }
  span {
    display: inline-block;
    padding-left: 6px;
  }
  &:hover {
    color: var(--color-red);
    svg {
      fill: var(--color-red) !important;
    }
  }
}