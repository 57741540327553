/* autoprefixer grid: no-autoplace */
@import '../base/_queries';
@import '../base/_typography';

$c-primary: #f35c49;
$c-secondary: #23334b;

.header {
  @include mq($from: 660px) {
    display: grid;
    grid-template-areas: 'branding nav';
    grid-template-columns: 1fr 3fr;
  }

  @include mq($from: 780px) {
    grid-template-columns: 1fr 2fr;
  }
}

.branding {
  @include mq($from: 600px) {
    grid-area: branding;
  }
}

.nav-area {
  transform: translateY(-100%);
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: transform 0.3s ease, opacity 0.3s ease, max-height 0.3s ease;

  @include mq($until: 660px) {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
  }

  &.active {
    transform: translateY(0);
    max-height: 500px;
    opacity: 1;
    overflow: visible;
    @include mq($until: 660px) {
      padding: 70px 0;
    }
  }
}

@include mq($from: 660px) {
  .nav-area {
    transform: translateY(0);
    max-height: none;
    opacity: 1;
    overflow: visible;
    padding: 0;
  }
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .nav-area {
    background: white;
  }
}

@media (forced-colors: active) and (prefers-color-scheme: light) {
  .nav-area {
    background: black;
  }
}

.branding__logo {
  top: 0;
  left: 0;
  display: inline-block;
  background-color: var(--color-red);
  z-index: 3;
  width: auto;
  max-width: 100%;
  padding: 20px 25px 12px 21px;
  @include mq($until: 400px) {
    width: 270px;
  }
  @include mq($from: 660px) {
    padding: 20px 25px 12px 21px;
  }
  @include mq($from: 660px, $until: 779px) {
    padding: 13px 20px 5px 20px;
    width: 210px;
  }
  @include mq($from: 779px, $until: 1000px) {
    padding: 20px 25px 12px 21px;
    width: 260px;
  }
  @include mq($from: 1000px) {
    padding: 20px 25px 12px 21px;
    width: auto;
  }
  @include mq($until: 1600px) {
    margin-left: -20px;
  }
  > svg {
    width: auto;
    max-width: 100%;
  }
  &:focus {
    background-color: lighten($c-primary, 2%);
  }
}

.header.without-cover {
  @include mq($from: 660px) {
    position: sticky;
    top: 0;
    background: linear-gradient(
      rgba(249, 250, 246, 1) 98%,
      rgba(249, 250, 246, 0) 100%
    );
  }
  z-index: 1;
}

.header {
  position: relative;
  z-index: 2;
  transition: opacity 2s ease-in 3s;
  opacity: 1;
}

.header.hidden {
  z-index: 2;
  opacity: 0;
}

.header.with-cover {
  opacity: 1;
}

.navigation {
  transition: color 1s;
  padding-top: 3vw;
  padding-bottom: 15px;
  z-index: 3;
  @include mq($from: 600px) {
    @include poly-fluid-sizing(
      'padding-top',
      (
        600px: 21px,
        779px: 19px,
        780px: 26px,
        940px: 24px,
        1100px: 20px,
      )
    );
  }
  @include mq($from: 780px) {
    padding-bottom: 50px;
  }
  a {
    transition: color 1s;
  }
}

.navigation.with-cover {
  color: white;
  .navigation__item a {
    color: #fff;
    &:hover {
      color: var(--color-red);
    }
    &.active {
      color: #fff;
      text-decoration: none;
      &:visited {
        color: #fff;
      }
      &:hover {
        color: var(--color-red);
      }
    }
  }
}

.navigation__list {
  font-style: bold;
  letter-spacing: -0.03em;
  list-style: none;
  padding: 0;
  margin: 0;
  @include poly-fluid-sizing(
    'font-size',
    (
      200px: 38px,
      659px: 38px,
      660px: 17px,
      680px: 18px,
      763px: 21px,
      780px: 20px,
      $breakpoint-large: 28px,
    )
  );
  line-height: normal;
  @include mq($from: 660px) {
    line-height: 1.208;
  }
}

.navigation__item {
  display: inline-block;
  @include mq($until: 659px) {
    display: block;
    &:after {
      content: ' /';
      font-weight: bold;
    }
    &:before {
      content: '';
      display: none;
    }
  }
}

.navigation__item:first-of-type:before {
  content: '';
  display: none;
}

.navigation__item:before {
  display: none;
  font-weight: bold;
  content: '/';

  $breakpoints: (
    660px: 2px,
    700px: 1.5px,
    825px: 3px,
    900px: 2.5px,
    1000px: 2px,
    1100px: 1.5px,
    1200px: 1.3px,
  );

  @each $breakpoint, $margin in $breakpoints {
    @include mq($from: $breakpoint) {
      margin-left: $margin;
      margin-right: $margin;
      display: inline-block;
    }
  }
}

.navigation__item a {
  color: var(--color-text);
  font-weight: bold;
  text-decoration: none;
  @include mq($until: 659px) {
    line-height: 1.18;
    display: inline-block;
    margin-bottom: 6px;
  }
  &:hover {
    color: $c-primary;
  }
}

.with-cover .navigation__item a.active {
  border-bottom: none;
}

.navigation__item a.active {
  color: var(--color-red);
  text-decoration: none;
  border-bottom: 4px solid var(--color-red);
}

.navigation__item a:hover {
  color: var(--color-red);
}

.navigation__item a:visited {
  color: var(--color-text);
  &:hover {
    color: var(--color-red);
  }
}

.navigation__item a.active:visited {
  color: var(--color-red);
}

.menu-icon {
  display: none;
  position: absolute;
  top: 18px;
  right: 10px;
  .hamburger-inner,
  .hamburger-inner__before,
  .hamburger-inner__after {
    height: 5px;
    border-radius: 0;
  }
  &:focus-within {
    .hamburger-inner,
    .hamburger-inner__before,
    .hamburger-inner__after {
      background-color: var(--color-text);
    }
    > span {
      color: var(--color-text);
    }
  }
  > span {
    display: block;
    line-height: 1;
    color: var(--color-red);
    text-transform: lowercase;
    font-weight: bold;
    font-size: 18px;
  }
  @include mq($until: 659px) {
    display: block;
  }
}

.with-cover {
  .menu-icon {
    > span {
      color: white;
    }
    &:focus-within {
      .hamburger-inner,
      .hamburger-inner__before,
      .hamburger-inner__after {
        background-color: var(--color-red);
      }
      > span {
        color: var(--color-red);
      }
    }
  }
}
.without-cover {
  .menu-icon {
    > span {
      color: var(--color-red);
    }
    &:focus-within {
      .hamburger-inner,
      .hamburger-inner__before,
      .hamburger-inner__after {
        background-color: var(--color-text);
      }
      > span {
        color: var(--color-text);
      }
    }
  }
}

.nav-slide {
  transform: translateY(-100%);
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: transform 0.3s ease, opacity 0.3s ease, max-height 0.3s ease;

  @include mq($until: 660px) {
    transform: translateY(-50px);
    max-height: 0;
    opacity: 0;
    overflow: hidden;
  }

  &.active {
    transform: translateY(0);
    max-height: 500px;
    opacity: 1;
    overflow: visible;
  }
}

@include mq($from: 660px) {
  .nav-slide {
    transform: translateY(0);
    max-height: none;
    opacity: 1;
    overflow: visible;
  }
}

.with-cover {
  #logo-part-agile {
    fill: white;
  }
  #logo-part-collective {
    fill: white;
  }
}

.without-cover {
  .branding__logo {
    background-color: var(--color-text);
    &:focus {
      background-color: lighten($c-secondary, 8%);
    }
  }
  #logo-part-agile {
    fill: white;
  }
  #logo-part-collective {
    fill: var(--color-red);
  }
}

@media (forced-colors: active) and (prefers-color-scheme: light) {
  .with-cover {
    #logo-part-agile {
      fill: black;
    }
    #logo-part-collective {
      fill: black;
    }
  }

  .without-cover {
    .branding__logo {
      background-color: var(--color-text);
      &:focus {
        outline: 3px solid white;
        outline-offset: -2px;
      }
    }
    #logo-part-agile {
      fill: black;
    }
    #logo-part-collective {
      fill: black;
    }
  }
}

@media (forced-colors: active) and (prefers-color-scheme: dark) {
  .with-cover {
    #logo-part-agile {
      fill: white;
    }
    #logo-part-collective {
      fill: white;
    }
  }

  .without-cover {
    .branding__logo {
      background-color: var(--color-text);
      &:focus {
        outline: 3px solid white;
        outline-offset: -2px;
      }
    }
    #logo-part-agile {
      fill: white;
    }
    #logo-part-collective {
      fill: white;
    }
  }
}
