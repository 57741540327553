@import '../base/_typography';
@import '../base/_queries';

.address {
    margin-top: 50px;
    max-width: 315px;
    line-height: 1.04;
    @include poly-fluid-sizing('font-size', ($breakpoint-tiny: 18px, 768px: 24px, $breakpoint-large: 27px));
    letter-spacing: -0.02em;
    font-weight: bold;
    @include mq($until: 767px) {
      border-top: 7px solid var(--color-red);
      max-width: 100%;
      padding-top: 50px;
      padding-right: 10px;
    }
    @include mq($from: $breakpoint-medium) {
      position: fixed;
      bottom: 30px;
    }
  }
  
  .address a,
  .address a:visited {
    color: var(--color-blue-dark);
    &:hover {
      color: var(--color-red);
    }
  }
  
  .address__company_name {
    display: none;
  }
  
  .address--not-sticky {
    @include mq($from: $breakpoint-medium) {
      position: absolute;
      bottom: 28px;
      left: 0;
    }
  }