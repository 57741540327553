/**
 * Font definitions.
 */

@font-face {
  font-family: apercu;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('/_assets/fonts/subset-ApercuPro.woff2') format('woff2'),
    url('/_assets/fonts/subset-ApercuPro.woff') format('woff');
}

@font-face {
  font-family: apercu;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('/_assets/fonts/subset-ApercuPro-Bold.woff2') format('woff2'),
    url('/_assets/fonts/subset-ApercuPro-Bold.woff') format('woff');
}

@font-face {
  font-family: roboto-mono;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('/_assets/fonts/roboto-mono-v5-latin-regular.woff2') format('woff2'),
    url('/_assets/fonts/roboto-mono-v5-latin-regular.woff') format('woff'),
    url('/_assets/fonts/roboto-mono-v5-latin-regular.ttf') format('truetype');
}
